import { Button } from "primereact/button"

//PrimeReact
import { InputTextarea } from "primereact/inputtextarea"
import { Tooltip } from "primereact/tooltip"

// Context
import { UserContext } from "../../../context/userContext"

//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import { useContext, useState } from "react"
import AssessmentFormOutcomeEntry from "./AssessmentFormOutcomeEntry"

export default function AssessmentFormOutcome({
  answers,
  outcomes,
  outcomesSelected,
  remarksValue,
  onSubmit,
  onCancel,
  disable,
  completedAssessment,
  validateScore,
  minScore,
  maxScore,
}) {
  const userCtx = useContext(UserContext)

  const disableCompletedAssessment = completedAssessment && disable

  const [currentOutcomes, setCurrentOutcomes] = useState(outcomesSelected || [])
  const [remarks, setRemarks] = useState(remarksValue)

  const calculateScore = () => {
    let score = 0

    answers.answers.forEach((answer) => {
      const answerType = answer.type.toLowerCase()

      if (!["textinput", "date"].includes(answerType)) {
        if (["dropdown", "radio"].includes(answerType)) {
          let answerOption = answer.multiValues.filter(
            (option) => option.optionId === answer.value
          )
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        } else if (answerType === "number" && answer.scoreFromAnswer) {
          score = score + (+answer.value || 0)
        } else if (answerType === "checkbox") {
          let answerOption = answer.multiValues.filter((option) => option.value)
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        }
      }
    })

    return score
  }

  const score = calculateScore()
  let scoreValidationMessage = ""

  if (validateScore) {
    if (score < minScore) {
      scoreValidationMessage = `Score validation failed: The score is below the allowed minimum of ${minScore}, please review assessment answers`
    } else if (score > maxScore) {
      scoreValidationMessage = `Score validation failed: The score is more than the allowed maximum of ${maxScore}, please review assessment answers`
    }
  }

  let filteredSuggestedOutcomes = disableCompletedAssessment
    ? currentOutcomes
    : outcomes?.filter((x) => x.scoreMin <= score && x.scoreMax >= score)

  if (
    !disableCompletedAssessment ||
    (currentOutcomes.length > 0 &&
      currentOutcomes.some((outcome) => outcome.suggestedOutcomeId === null))
  ) {
    filteredSuggestedOutcomes = [
      ...filteredSuggestedOutcomes,
      { id: null, text: "Other" },
    ]
  }

  const changeSelectedOutcome = (
    suggestedOutcomeId,
    isSelected,
    otherValue
  ) => {
    let selectedOutcome = currentOutcomes?.filter(
      (outcome) => outcome.suggestedOutcomeId === suggestedOutcomeId
    )

    if (selectedOutcome.length > 0) {
      let outcome = selectedOutcome[0]
      outcome.selected = isSelected
      outcome.otherFinalOutcome = otherValue

      setCurrentOutcomes([
        ...currentOutcomes?.filter(
          (outcome) => outcome.suggestedOutcomeId !== suggestedOutcomeId
        ),
        outcome,
      ])
    } else {
      setCurrentOutcomes([
        ...currentOutcomes,
        {
          suggestedOutcomeId: suggestedOutcomeId,
          selected: isSelected,
          otherFinalOutcome: otherValue,
        },
      ])
    }
  }

  return (
    <div
      className={
        disableCompletedAssessment
          ? myStyles.outcomeCompletedLayout
          : myStyles.outcomeLayout
      }
    >
      {!disableCompletedAssessment || completedAssessment.displayScore ? (
        <>
          <Tooltip target={".tt-score"} />
          <div
            className={`${myStyles.outcomeLayoutScore} ${
              scoreValidationMessage ? "tt-score" : ""
            }`}
            data-pr-tooltip={
              scoreValidationMessage ? scoreValidationMessage : ""
            }
          >
            <div>Score</div>
            <div className={myStyles.ReadOnlyTextContainer}>
              <label className={`${myStyles.scoreText}`}>{score}</label>{" "}
              {scoreValidationMessage ? (
                <>
                  <label className={`${myStyles.scoreTextError}`}> !</label>
                  <div className={`${myStyles.scoreTextErrorDiv}`}>
                    <label> {scoreValidationMessage}</label>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}

      {!disableCompletedAssessment || completedAssessment.displayDisposition ? (
        <>
          <div className={myStyles.outcomeLayoutOutcomeHeader}>
            {disableCompletedAssessment
              ? "Final Outcomes"
              : "Suggested Outcomes"}
          </div>
          <div className={myStyles.outcomeLayoutOutcomes}>
            {filteredSuggestedOutcomes &&
            filteredSuggestedOutcomes?.length > 0 ? (
              filteredSuggestedOutcomes.map((outcome) => {
                return (
                  <AssessmentFormOutcomeEntry
                    outcome={outcome}
                    selectedOutcomes={currentOutcomes}
                    onUpdateOutcome={changeSelectedOutcome}
                    key={outcome.id}
                    disabled={disable}
                    readOnlyView={disableCompletedAssessment}
                  />
                )
              })
            ) : (
              <>Outcome not found</>
            )}
          </div>
        </>
      ) : null}

      {!disableCompletedAssessment || completedAssessment.displayRemarks ? (
        <>
          <div className={myStyles.outcomeLayoutRemarksHeader}>Notes</div>
          <div className={myStyles.outcomeLayoutRemarks}>
            <InputTextarea
              value={remarks}
              onChange={(e) => {
                setRemarks(e.target.value)
              }}
              className={myStyles.outcomeInput}
              disabled={disable}
            />
          </div>
        </>
      ) : null}

      {!disableCompletedAssessment || !disable ? (
        <div className={myStyles.outcomeLayoutFooter}>
          <Button
            type="button"
            label={"Back"}
            onClick={() => {
              onCancel(currentOutcomes, remarks)
            }}
            className={assessStyles.button}
            disabled={disable}
          />
          <Button
            type="button"
            label={"Submit assessment"}
            onClick={() => {
              let useCurrentOutcomes = [...currentOutcomes]

              //Check for any currentOutcomes that are out of scope for the score and remove them.
              currentOutcomes.forEach((finalOutcome) => {
                if (finalOutcome.selected && finalOutcome.suggestedOutcomeId) {
                  const suggestedOutcome = outcomes.find(
                    (outcome) => outcome.id === finalOutcome.suggestedOutcomeId
                  )

                  if (suggestedOutcome) {
                    if (
                      score < suggestedOutcome.scoreMin ||
                      score > suggestedOutcome.scoreMax
                    ) {
                      useCurrentOutcomes = useCurrentOutcomes.filter(
                        (outcome) => outcome !== finalOutcome
                      )
                    }
                  } else {
                    userCtx.setNotificationError(
                      "Unable to validate outcome score range"
                    )
                    return
                  }
                }
              })

              if (
                useCurrentOutcomes.filter(
                  (outcome) =>
                    !outcome.suggestedOutcomeId &&
                    outcome.selected &&
                    !outcome.OtherFinalOutcome
                ).length > 0
              ) {
                userCtx.setNotificationError(
                  "Please enter a description for the selected `Other` outcome option"
                )
              } else if (scoreValidationMessage) {
                userCtx.setNotificationError(scoreValidationMessage)
              } else {
                onSubmit(useCurrentOutcomes, remarks)
              }
            }}
            className={`${assessStyles.button} ${myStyles.noMarginButton}`}
            disabled={disable}
          />
        </div>
      ) : null}
    </div>
  )
}
