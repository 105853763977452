import { useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

//PrimeReact
import { Button } from "primereact/button"
import { TabPanel, TabView } from "primereact/tabview"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//GraphQL
import { useLazyQuery } from "@apollo/client"
import { GET_LATEST_SERVICE_EPISODE } from "../queries"

//SOAR Components
import ParticipantSummaryInfo from "../../../components/sidebars/ParticipantSummaryInfo"

//Assessments components
import ParticipantAssessmentsOverview from "./ParticipantAssessmentsOverview"
import ParticipantAssessmentsCompleted from "./ParticipantAssessmentsCompleted"
import AssessmentForm from "../AssessmentForm/AssessmentForm"
import ParticipantAssessmentsSchedules from "./ParticipantAssessmentsSchedules"
import ParticipantAssessmentBlockDialog from "./ParticipantAssessmentBlockDialog"

//Helpers
import { LogError } from "../../../helpers/logger"
import isParticipantActive from "../../../helpers/isParticipantActive"
import InactiveParticipantToolTip from "./InactiveParticipantToolTip"

export default function ParticipantAssessments() {
  const userCtx = useContext(UserContext)
  const location = useLocation()

  const participantIsActive = isParticipantActive(
    userCtx.participant_membership_status
  )

  const [tabViewIndex, setTabViewIndex] = useState(0)

  //Assessment block values:
  const [showAssessmentBlock, setShowAssessmentBlock] = useState(false)
  const [scheduledTaskId, setScheduledTaskId] = useState(undefined)

  //Assessment form values:
  const [newAssessmentId, setNewAssessmentId] = useState(undefined)
  const [completedId, setCompletedId] = useState(undefined)
  const [openReadOnly, setOpenReadOnly] = useState(true)
  const [showAssessmentForm, setShowAssessmentForm] = useState(false)

  const [triggerRefresh, setTriggerRefresh] = useState(false)
  const [participantServiceEpisode, setParticipantServiceEpisode] = useState({
    isLoading: true,
  })

  const facilitatorProfilesRef = useRef([])

  const [getParticipant] = useLazyQuery(GET_LATEST_SERVICE_EPISODE, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: "",
      participant_id: "",
      organization_id: "",
      participant_membership_id: "",
    },
  })

  useEffect(() => {
    if (location.state) {
      if (location.state.taskId) {
        //Open the Scheduled assessments tab view
        setTabViewIndex(2)
        //Show the task form to complete the task
        updateShowAssessmentBlock(true, location.state.taskId)
      } else if (location.state.page) {
        switch (location.state.page) {
          case "schedule":
            setTabViewIndex(2)
            break
          default:
            break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    getParticipant({
      variables: {
        staff_id: "-12", //Assessments service user
        participant_id: userCtx.participant_id,
        organization_id: userCtx.organization_id,
        participant_membership_id: userCtx.participant_membership_id,
      },
    })
      .then((result) => {
        if (result.data) {
          setParticipantServiceEpisode(result.data)
        } else {
          LogError("Unable to retrieve service episode data", result)
          userCtx.setNotificationError(
            "Unable to retrieve service episode data"
          )
          setParticipantServiceEpisode(undefined)
        }
      })
      .catch((error) => {
        LogError("Unable to retrieve service episode data", error)
        userCtx.setNotificationError("Unable to retrieve service episode data")
        setParticipantServiceEpisode(undefined)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.participant_id, userCtx.organization_id])

  const updateShowAssessmentBlock = (showBlock, showScheduledTaskId) => {
    if (showBlock) {
      setScheduledTaskId(showScheduledTaskId)
      setShowAssessmentBlock(true)
    } else {
      setShowAssessmentBlock(false)
      setScheduledTaskId(undefined)
    }
  }

  const setAssessmentId = (id, isCompletedId, readOnly) => {
    if (!isCompletedId) {
      setNewAssessmentId(id)
      setCompletedId(undefined)
      setOpenReadOnly(false)
      setShowAssessmentForm(true)
    } else {
      setNewAssessmentId(undefined)
      setCompletedId(id)
      setOpenReadOnly(readOnly || true)
      setShowAssessmentForm(true)
    }
  }

  const onSaveFacilitator = (profile_id, organization_id, name) => {
    let currentFacilitators = facilitatorProfilesRef.current

    if (
      !currentFacilitators.some(
        (profile) =>
          profile.profile_id === profile_id &&
          profile.organization_id === organization_id
      )
    ) {
      currentFacilitators = [
        ...currentFacilitators,
        { profile_id, organization_id, name },
      ]

      facilitatorProfilesRef.current = currentFacilitators
    }

    return facilitatorProfilesRef.current
  }

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />
      {showAssessmentBlock ? (
        <ParticipantAssessmentBlockDialog
          showForm={true}
          scheduledTaskId={scheduledTaskId}
          onCancel={() => {
            updateShowAssessmentBlock(false)
            setTriggerRefresh(!triggerRefresh)
          }}
        />
      ) : null}
      {showAssessmentForm ? (
        <AssessmentForm
          showForm={showAssessmentForm}
          onCancel={() => {
            setShowAssessmentForm(false)
            setCompletedId(undefined)
          }}
          onSubmit={() => {
            setShowAssessmentForm(false)
            setCompletedId(undefined)
            setTriggerRefresh(!triggerRefresh)
          }}
          assessmentId={newAssessmentId}
          completedAssessmentId={completedId}
          readOnly={openReadOnly}
        />
      ) : null}

      <div className="main-content">
        <div className={myStyles.pageHeader}>
          <div className={myStyles.pageHeader}>
            <h1>Assessments</h1>
          </div>
          <div className={myStyles.pageHeaderActions}>
            <InactiveParticipantToolTip>
              <Button
                label={"Start new assessment"}
                type="button"
                className={`${assessStyles.button} ${myStyles.pageHeaderActionButton}`}
                onClick={() => {
                  updateShowAssessmentBlock(true)
                }}
                disabled={!participantIsActive}
              />
            </InactiveParticipantToolTip>
          </div>
        </div>

        <div className={assessStyles.tabView}>
          <TabView
            activeIndex={tabViewIndex}
            onTabChange={(e) => {
              setTabViewIndex(e.index)
            }}
          >
            <TabPanel header={"Overview"}>
              <ParticipantAssessmentsOverview
                participantServiceEpisode={participantServiceEpisode}
                onViewAssessment={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true)
                }}
                facilitatorProfiles={facilitatorProfilesRef.current}
                onSaveFacilitatorProfile={onSaveFacilitator}
              />
            </TabPanel>
            <TabPanel header={"Completed"}>
              <ParticipantAssessmentsCompleted
                triggerRefresh={triggerRefresh}
                onEdit={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true, false)
                }}
                onView={(completedAssessmentId) => {
                  setAssessmentId(completedAssessmentId, true, true)
                }}
                facilitatorProfiles={facilitatorProfilesRef.current}
                onSaveFacilitatorProfile={onSaveFacilitator}
              />
            </TabPanel>
            <TabPanel header={"Scheduled"}>
              <ParticipantAssessmentsSchedules
                triggerRefresh={triggerRefresh}
                onProcessScheduledTask={(scheduledTaskId) => {
                  updateShowAssessmentBlock(true, scheduledTaskId)
                }}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  )
}
