import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"

// Context
import { UserContext } from "../../../context/userContext"

//PrimeReact
import { Dropdown } from "primereact/dropdown"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Paginator } from "primereact/paginator"
import { Chart } from "primereact/chart"
import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { Badge } from "primereact/badge"
import { Tooltip } from "primereact/tooltip"

//Helpers
import { LogError, GetLogLevelKey } from "../../../helpers/logger"
import { assessmentsApiUrl } from "../../../config/microservicesUrls"

//Styles
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import myStyles from "../../../styles/assessments/ParticipantAssessments.module.css"

//SOAR Components
import { LoaderMedium, LoaderSmall } from "../../../components/Loaders"

//Assessments components
import {
  assessmentsApiParticipantOverviewPost,
  assessmentsApiParticipantOverviewSummaryPost,
  assessmentsApiCompleteListPost,
} from "../assessmentsApi"
import ParticipantAssessmentFacilitator from "./ParticipantAssessmentFacilitator"

//SignalR
import { useClientMethod, useHub } from "react-use-signalr"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { useLazyQuery } from "@apollo/client"
import { GET_TEMPLATES } from "../queries"

export default function ParticipantAssessmentsOverview({
  participantServiceEpisode,
  facilitatorProfiles,
  onSaveFacilitatorProfile,
  onViewAssessment,
}) {
  const userCtx = useContext(UserContext)

  const overlayPanelRef = useRef(undefined)

  const [facilitators, setFacilitators] = useState(facilitatorProfiles)
  const [overView, setOverView] = useState(undefined)
  const [completedAssessments, setCompletedAssessments] = useState(undefined)
  const [pageSetup, setPageSetup] = useState({
    rows: 10,
    first: 0,
    page: 0,
  })

  const [overViewTableSort, setOverviewTableSort] = useState({
    field: "lastAssessmentDateValue",
    sortOrder: -1,
  })

  const [graphData, setGraphData] = useState(undefined)
  const [graphPeriod, setGraphPeriod] = useState("year")
  const [graphType, setGraphType] = useState("line")

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingGraph, setIsLoadingGraph] = useState(false)
  const [isLoadingAssessments, setIsLoadingAssessments] = useState(false)
  const [filterOptions, setFilterOptions] = useState([
    { label: "View all assessments", value: "0" },
  ])
  const [filterValue, setFilterValue] = useState("0")

  const [assessmentHubConnection, setAssessmentHubConnection] = useState(null)
  const [hasUpdate, setHasUpdate] = useState(false)

  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false)
  const [templates, setTemplates] = useState([])

  // SignalR
  useHub(assessmentHubConnection)

  useClientMethod(
    assessmentHubConnection,
    "SendAssessmentConfirmationNotification",
    (participantId, organizationId) => {
      if (
        participantId === userCtx.participant_id &&
        organizationId === userCtx.organization_id &&
        !hasUpdate
      ) {
        setHasUpdate(true)
        userCtx.setNotificationInformation(
          "There are newly completed assessments for this participant, please refresh the overview dataset to view updated values"
        )
      }
    }
  )

  function setupSignalRConnection() {
    try {
      // Setup Connection
      const connection = new HubConnectionBuilder()
        .withUrl(`${assessmentsApiUrl()}/hubs/clientComms`)
        .withAutomaticReconnect()
        .configureLogging(
          GetLogLevelKey() === "debug" ? LogLevel.Debug : LogLevel.Warning
        )
        .build()

      setAssessmentHubConnection(connection) // Save connection to state
    } catch (error) {
      setAssessmentHubConnection(undefined)
      LogError(
        "Unable to connect to to Notification Hub, retrying in 10 seconds",
        error
      )
    }
  }

  const [loadTemplatesQuery] = useLazyQuery(GET_TEMPLATES, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: "-12", //Service staff account: RecoveryLInk Microservices
      organization_id: userCtx.organization_id,
    },
  })

  useEffect(() => {
    setupSignalRConnection()
  }, [])

  const loadOverviewData = useCallback(async () => {
    try {
      if (!participantServiceEpisode?.isLoading) {
        setIsLoading(true)

        //Default to 1 year ago
        let startDate = DateTime.now().plus({ days: -365 })

        if (
          participantServiceEpisode?.serviceEpisode &&
          participantServiceEpisode?.serviceEpisode.length > 0
        ) {
          var firstEntry = participantServiceEpisode?.serviceEpisode.find(
            (entry) =>
              entry.episode_intake_date !== null &&
              entry.service_episodes_id.endsWith("100") //Intake entries end with 100, discharge with 200
          )
          if (firstEntry) {
            startDate = DateTime.fromFormat(
              firstEntry.episode_intake_date,
              "yyyy-MM-dd"
            )
          }
        }

        let payload = {
          participantId: userCtx.participant_id,
          organizationId: userCtx.organization_id,
          enterpriseId: userCtx.enterprise_id,
          startDate: startDate.toISO(),
          pageSize: 3,
          pageNumber: pageSetup.page + 1,
        }

        if (filterValue !== "0") {
          payload = { ...payload, assessmentId: filterValue }
        }

        const result = await assessmentsApiParticipantOverviewPost(
          userCtx.tokenAssessments,
          payload
        )

        result.assessmentTypes.forEach((type) => {
          type.lastAssessmentDateValue = type.lastAssessment
            ? DateTime.fromISO(type.lastAssessment, {
                zone: "utc",
              }).toJSDate()
            : undefined
        })

        setOverView(result)

        const newFilterOptions = [
          { label: "View all assessments", value: "0" },
          ...result.assessmentTypes?.map((type) => {
            return { label: type.assessmentName, value: type.assessmentId }
          }),
        ]

        setFilterOptions(newFilterOptions)

        setPageSetup({ ...pageSetup, first: 0, page: 0 })

        setIsLoading(false)
      }
    } catch (error) {
      LogError("Unable to load participant overview", error)
      userCtx.setNotificationError("Unable to load participant overview")
      setOverView(undefined)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterValue,
    userCtx.participant_id,
    userCtx.organization_id,
    participantServiceEpisode,
  ])

  useEffect(() => {
    loadOverviewData()
  }, [loadOverviewData])

  const loadOverviewGraphData = useCallback(async () => {
    try {
      setIsLoadingGraph(true)
      if (filterValue !== "0") {
        let payload = {
          participantId: userCtx.participant_id,
          organizationId: userCtx.organization_id,
          assessmentId: filterValue,
          periodType: graphPeriod,
        }

        const result = await assessmentsApiParticipantOverviewSummaryPost(
          userCtx.tokenAssessments,
          payload
        )

        //Summarize graph data
        let dataSet = {}

        result?.data.forEach((data) => {
          let dateKey = DateTime.fromISO(data.date)
            .toLocal()
            .toFormat("yyyyMMdd")

          if (Object.keys(dataSet).includes(dateKey)) {
            dataSet[dateKey].counter = dataSet[dateKey].counter + 1
            dataSet[dateKey].values = [...dataSet[dateKey].values, data.score]
            dataSet[
              dateKey
            ].label = `${dataSet[dateKey].date} [${dataSet[dateKey].values.length}] *`

            let totalScore = dataSet[dateKey].values.reduce((a, b) => a + b, 0)

            dataSet[dateKey].averageValue =
              totalScore / dataSet[dateKey].values.length
          } else {
            let dateString = DateTime.fromISO(data.date)
              .toLocal()
              .toLocaleString()

            let newDataSet = {
              ...dataSet,
            }

            newDataSet[dateKey] = {
              date: dateString,
              label: dateString,
              counter: 1,
              values: [data.score],
              averageValue: data.score,
            }

            dataSet = newDataSet
          }
        })

        setGraphData({
          labels: Object.keys(dataSet).map((dataKey) => dataSet[dataKey].label),
          datasets: [
            {
              label: "Score",
              data: Object.keys(dataSet).map(
                (dataKey) => dataSet[dataKey].averageValue
              ),
              borderColor: "#8467ad",
              backgroundColor: "#E7E0F1",
              fill: true,
              tension: 0.4,
            },
          ],
        })
        setIsLoadingGraph(false)
      }
    } catch (error) {
      LogError("Unable to load participant overview summary", error)
      userCtx.setNotificationError(
        "Unable to load participant overview summary"
      )
      setGraphData(undefined)
      setIsLoadingGraph(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterValue,
    graphPeriod,
    userCtx.participant_id,
    userCtx.organization_id,
  ])

  useEffect(() => {
    loadOverviewGraphData()
  }, [loadOverviewGraphData])

  const loadAssessmentData = useCallback(async () => {
    try {
      setIsLoadingAssessments(true)

      const payload = {
        sortField: "completedDate",
        assessmentIds: filterValue !== "0" ? [filterValue] : [],
        organizationId: userCtx.organization_id,
        participantId: userCtx.participant_id,
        pageSize: pageSetup.rows,
        pageNum: pageSetup.page + 1,
      }

      const result = await assessmentsApiCompleteListPost(
        userCtx.tokenAssessments,
        payload
      )

      setCompletedAssessments(result)

      setIsLoadingAssessments(false)
    } catch (error) {
      LogError("Unable to load completed assessments", error)
      userCtx.setNotificationError("Unable to load completed assessments")
      setCompletedAssessments(undefined)
      setIsLoadingAssessments(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSetup])

  useEffect(() => {
    loadAssessmentData()
  }, [loadAssessmentData])

  const loadTemplates = useCallback(async () => {
    try {
      setIsLoadingTemplates(true)

      const templates = await loadTemplatesQuery({
        variables: {
          staff_id: "-12", //Service staff account: RecoveryLInk Microservices
          organization_id: userCtx.organization_id,
        },
      })

      if (templates?.data?.templates) {
        setTemplates(templates?.data?.templates)
      } else {
        LogError("Loading SOAR templates failed", templates)
        setTemplates([])
      }

      setIsLoadingTemplates(false)
    } catch (error) {
      LogError("Unable to load template names", error)
      userCtx.setNotificationError("Unable to load template names")
      setTemplates([])
      setIsLoadingTemplates(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.organization_id])

  useEffect(() => {
    loadTemplates()
  }, [loadTemplates])

  const LastAssessmentDateColumnTemplate = (rowData) => {
    let date = rowData.lastAssessment || rowData.completedDate

    if (!!date) {
      const dateFromIso = DateTime.fromISO(date, { zone: "utc" })

      date = dateFromIso.toLocal().toLocaleString()
    }

    return <>{date}</>
  }

  const CollectionTypeTemplate = (rowData) => {
    if (isLoadingTemplates) {
      return <LoaderSmall />
    } else {
      const templateName = templates.find(
        (template) => template.idTemplate === rowData.templateId
      )

      if (templateName) {
        return <>{templateName?.template_title}</>
      } else {
        return <>{rowData.templateId}</>
      }
    }
  }

  const ActionColumnTemplate = (rowData) => {
    return (
      <>
        <Button
          type="button"
          onClick={() => {
            onViewAssessment(rowData.id)
          }}
          className={assessStyles.actionButtonPurple}
          tooltip="View assessment"
          tooltipOptions={{ position: "bottom" }}
        >
          <span className={`material-icons`}>{"visibility"}</span>
        </Button>
      </>
    )
  }

  const OverviewActionColumnTemplate = (rowData) => {
    return (
      <>
        <Button
          type="button"
          onClick={() => {
            setFilterValue(rowData.assessmentId)
          }}
          className={assessStyles.actionButtonPurple}
          tooltip="Show detail for this assessment type"
          tooltipOptions={{ position: "bottom" }}
        >
          <span className={`material-icons`}>{"visibility"}</span>
        </Button>
      </>
    )
  }

  const OverviewTable = () => {
    return (
      <DataTable
        value={overView?.assessmentTypes || []}
        className={assessStyles.dataTable}
        sortField={overViewTableSort.field}
        sortOrder={overViewTableSort.sortOrder}
        onSort={(e) => {
          setOverviewTableSort({ field: e.sortField, sortOrder: e.sortOrder })
        }}
        paginator
        rows={10}
      >
        <Column header={"Assessment Name"} field={"assessmentName"} sortable />
        <Column header={"Completed assessments"} field={"count"} sortable />
        <Column
          header={"Date of last administration"}
          body={LastAssessmentDateColumnTemplate}
          field={"lastAssessmentDateValue"}
          sortable
        />
        <Column body={OverviewActionColumnTemplate} />
      </DataTable>
    )
  }

  const OverViewGraph = () => {
    if (isLoadingGraph) {
      return <LoaderMedium />
    } else if (graphData) {
      let options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
      }

      let graphTypeValue = graphType
      if (graphType === "verticalBar") {
        graphTypeValue = "bar"
        options = { ...options, indexAxis: "y" }
      }

      let assessmentProfile = filterOptions.filter(
        (option) => option.value === filterValue
      )

      return (
        <div className={myStyles.overviewGraphLayout}>
          <div className={myStyles.overviewGraphLayoutHeader}>
            Scores for{" "}
            {assessmentProfile.length > 0
              ? assessmentProfile[0].label
              : "selected assessment"}
          </div>
          <div className={myStyles.overviewGraphLayoutPeriod}>
            <Dropdown
              value={graphPeriod}
              options={[
                { label: "All time", value: "all" },
                { label: "Last year", value: "year" },
                { label: "Last 180 days", value: "180days" },
                { label: "Last 90 days", value: "90days" },
                { label: "Last 30 days", value: "30days" },
                { label: "Last 7 days", value: "7days" },
              ]}
              onChange={(e) => {
                setGraphPeriod(e.value)
              }}
              className={myStyles.overviewGraphDropDown}
            />
          </div>
          <div className={myStyles.overviewGraphLayoutType}>
            <Dropdown
              value={graphType}
              options={[
                { label: "Line", value: "line" },
                { label: "Bar", value: "bar" },
                { label: "Vertical Bar", value: "verticalBar" },
              ]}
              onChange={(e) => {
                setGraphType(e.value)
              }}
              className={myStyles.overviewGraphDropDown}
            />
          </div>
          <div className={myStyles.overviewGraphLayoutGraph}>
            <Chart type={graphTypeValue} data={graphData} options={options} />
            {graphData?.labels?.some((label) => label.includes("*")) ? (
              <span className={myStyles.graphHelper}>
                * Dates with a [number] indicate that there are multiple
                assessments on the day and the score is an average
              </span>
            ) : null}
          </div>
        </div>
      )
    }
  }

  const getAverageAssessmentsCompleted = () => {
    if (
      participantServiceEpisode &&
      participantServiceEpisode?.serviceEpisode &&
      participantServiceEpisode?.serviceEpisode.length > 0 &&
      participantServiceEpisode?.serviceEpisode[0]?.episode_intake_date
    ) {
      const serviceEpisode = participantServiceEpisode?.serviceEpisode[0]
      const startDate = DateTime.fromFormat(
        serviceEpisode.episode_intake_date,
        "yyyy-MM-dd"
      )

      const dischargeDate = serviceEpisode.episode_discharge_date
        ? DateTime.fromFormat(
            serviceEpisode.episode_discharge_date,
            "yyyy-MM-dd"
          )
        : DateTime.now()

      if (!startDate.invalid) {
        const difference = dischargeDate.diff(startDate, ["months"])
        return (
          Math.round(
            (overView?.assessmentsGiven / difference.values.months) * 100
          ) / 100
        )
      }
    }

    return "Unavailable"
  }

  const getLastAssessmentDate = () => {
    return overView?.lastAssessmentDate
      ? DateTime.fromISO(overView.lastAssessmentDate, {
          zone: "utc",
        })
          .toLocal()
          .toLocaleString()
      : "Unavailable"
  }

  return (
    <>
      <OverlayPanel
        ref={overlayPanelRef}
        className={assessStyles.InfoOverlayPanel}
      >
        <p className={assessStyles.InfoOverlayText}>
          To view time series outcomes for a specific assessment, <br />
          please select the assessment from the filter list.
        </p>
      </OverlayPanel>
      <div className={myStyles.OverviewLayout}>
        <div
          className={`${myStyles.OverviewLayoutHeader} ${assessStyles.sectionMarginBottom}`}
        >
          <h2>Metrics and Reports</h2>
        </div>
        <div
          className={`${myStyles.OverviewLayoutAction} ${assessStyles.sectionMarginBottom}`}
        >
          <div
            className={`${assessStyles.InfoOverlayIconContainer} ${myStyles.InfoOverlayIconContainer}`}
            onMouseEnter={(e) => {
              overlayPanelRef.current.show(e)
            }}
            onMouseLeave={(e) => {
              overlayPanelRef.current.hide(e)
            }}
            onClick={(e) => {}}
          >
            <span className={`material-icons`}>info</span>
          </div>
          <Dropdown
            value={filterValue}
            options={filterOptions}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => {
              setFilterValue(e.value)
            }}
          />
          <div className={myStyles.refreshContainer}>
            <Button
              className={`${assessStyles.button} ${myStyles.refreshButton}`}
              onClick={() => {
                loadOverviewData()

                if (hasUpdate) {
                  setHasUpdate(false)
                }
              }}
              tooltip={`${
                hasUpdate
                  ? "Refresh data, newly confirmed assessments available"
                  : "Refresh data"
              }`}
              tooltipOptions={{ position: "bottom" }}
            >
              <span className={`material-icons`}>refresh</span>
            </Button>
            {hasUpdate ? (
              <Badge
                severity="warning"
                value={"!"}
                className={myStyles.refreshBadge}
              />
            ) : null}
          </div>
        </div>
        {isLoading || participantServiceEpisode?.isLoading ? null : (
          <>
            <div className={myStyles.OverviewLayoutSummary}>
              <div className={myStyles.overviewSummaryLayout}>
                <Tooltip target=".ttSummary" />
                <div className={myStyles.overviewSummaryLayoutLogo}>
                  <span className={`material-icons`}>{"assignment"}</span>
                </div>
                <div
                  className={`${myStyles.overviewSummaryLayoutDescription} ${
                    filterValue === "0" ? "ttSummary" : ""
                  }`}
                  data-pr-tooltip="The total number of assessments completed for this participant in the current service episode"
                  data-pr-position="bottom"
                >
                  {filterValue === "0"
                    ? "Completed Assessments"
                    : "Baseline score"}
                </div>
                <div
                  className={`${myStyles.overviewSummaryLayoutCounter} ${
                    filterValue === "0" ? "ttSummary" : ""
                  }`}
                  data-pr-tooltip="The total number of assessments completed for this participant in the current service episode"
                  data-pr-position="bottom"
                >
                  {(filterValue === "0"
                    ? overView?.assessmentsGiven
                    : overView?.filteredBaselineScore) || 0}
                </div>
              </div>
              <div className={myStyles.overviewSummaryLayout}>
                <div className={myStyles.overviewSummaryLayoutLogo}>
                  <span className={`material-icons`}>{"task"}</span>
                </div>
                <div
                  className={`${myStyles.overviewSummaryLayoutDescription} ${
                    filterValue === "0" ? "ttSummary" : ""
                  }`}
                  data-pr-tooltip="The average number of assessments completed for this participant per month since start of current service episode"
                  data-pr-position="bottom"
                >
                  {filterValue === "0" ? "Monthly Average" : "Current score"}
                </div>
                <div
                  className={`${myStyles.overviewSummaryLayoutCounter} ${
                    filterValue === "0" ? "ttSummary" : ""
                  }`}
                  data-pr-tooltip="The average number of assessments completed for this participant per month since start of current service episode"
                  data-pr-position="bottom"
                >
                  {(filterValue === "0"
                    ? getAverageAssessmentsCompleted()
                    : overView?.filteredLatestScore) || 0}
                </div>
              </div>
              <div className={myStyles.overviewSummaryLayout}>
                <div className={myStyles.overviewSummaryLayoutLogo}>
                  <span className={`material-icons`}>
                    {filterValue === "0" ? "pending_actions" : "checklist"}
                  </span>
                </div>
                <div className={myStyles.overviewSummaryLayoutDescription}>
                  {filterValue === "0" ? "Last assessment" : "Average score"}
                </div>
                <div className={myStyles.overviewSummaryLayoutCounter}>
                  {filterValue === "0"
                    ? getLastAssessmentDate()
                    : overView?.filteredAverageScore}
                </div>
              </div>
            </div>
            <div className={myStyles.OverviewLayoutGraph}>
              {filterValue === "0" ? <OverviewTable /> : <OverViewGraph />}
            </div>
            {!isLoadingAssessments ? (
              <div className={myStyles.OverviewLayoutTable}>
                <DataTable
                  value={completedAssessments?.assessments || []}
                  className={assessStyles.dataTable}
                >
                  <Column header={"Assessment Name"} field={"assessmentName"} />
                  <Column
                    header={"Date"}
                    body={LastAssessmentDateColumnTemplate}
                  />
                  <Column
                    header={"Collection Type"}
                    body={CollectionTypeTemplate}
                  />
                  <Column header={"Score"} field={"score"} />
                  <Column
                    header={"Facilitator"}
                    body={(rowData) => {
                      return (
                        <ParticipantAssessmentFacilitator
                          assessment={rowData}
                          facilitatorProfiles={facilitators}
                          onSaveFacilitatorProfile={(
                            profile_id,
                            organization_id,
                            name
                          ) => {
                            const newFacilitators = onSaveFacilitatorProfile(
                              profile_id,
                              organization_id,
                              name
                            )

                            if (
                              newFacilitators &&
                              newFacilitators !== facilitators
                            ) {
                              setFacilitators(newFacilitators)
                            }
                          }}
                        />
                      )
                    }}
                  />
                  <Column body={ActionColumnTemplate} />
                </DataTable>
                <Paginator
                  first={
                    completedAssessments
                      ? (completedAssessments?.pageNum - 1) *
                        completedAssessments?.pageSize
                      : 0
                  }
                  rows={completedAssessments?.pageSize || 0}
                  totalRecords={completedAssessments?.numTotal || 0}
                  onPageChange={(setup) => {
                    setPageSetup(setup)
                  }}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {isLoading ||
      isLoadingAssessments ||
      participantServiceEpisode?.isLoading ? (
        <div style={{ width: "100%" }}>
          <LoaderMedium />
        </div>
      ) : null}
    </>
  )
}
